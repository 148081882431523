define('video_facade',['window', 'jquery', 'gusto-core', 'domready!'], function(window, $, gusto) {
    'use strict';

    var videoFacade = {
        facadeClass: '.video-facade',

        init: function () {
            this.findAndReplace($(this.facadeClass));
        },

        findAndReplace: function ($elements) {
            $elements.each(function() {
                var $facade = $(this);
                var provider = $facade.data('video-provider');

                if ($facade.data('facade-initialized') === 'true') return;

                if (provider === 'youtube') {
                    videoFacade.replaceYoutubeFacade($facade);
                }

                if (provider === 'brightcove') {
                    videoFacade.replaceBrightcoveFacade($facade);
                }

                $facade.attr('data-facade-initialized', true);
            });
        },

        replaceYoutubeFacade: function ($facade) {
            $facade.mouseover(videoFacade.preconnect);

            var width = $facade.data('width');
            var height = $facade.data('height');
            var src = $facade.data('src') + '&autoplay=1&playsinline=1';

            var $embed = $(document.createElement('iframe'))
                .attr('class', 'js_youtube_embed')
                .attr('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture')
                .attr('allowFullscreen', true)
                .attr('frameborder', '0')
                .attr('height', height)
                .attr('width', width)
                .attr('src', src);

            $facade.click(function() {
                videoFacade.replaceHtml($facade, $embed);
            });
        },

        replaceBrightcoveFacade: function ($facade) {
            var videoId = $facade.data('video-id');
            var account = $facade.data('account');
            const adTag = 'https://pubads.g.doubleclick.net/gampad/ads?iu=/21824617191/' + gusto.PROJECT_NAME +
            '/video/brightcove_player&description_url={window.location.href}&tfcd=0&npa=1&sz=400x300%7C640x480&cmsid=2527446' +
            '&vid={mediainfo.id}&ad_rule=1&gdfp_req=1&output=xml_vast&unviewed_position_start=1&env=vp&impl=s&correlator={random}';

            var $embed = $(document.createElement('video-js'))
                .attr('id', 'bc_player_' + videoId)
                .attr('class', 'video-js')
                .attr('controls', true)
                .attr('autoplay', false)
                .attr('playsinline', true)
                .attr('data-video-id', videoId)
                .attr('data-account', account)
                .attr('data-player', 'default')
                .attr('data-embed', 'default')
                .attr('style', 'position:absolute;top:0;left:0;width:100%;height:100%;');

            $facade.click(function() {
                videoFacade.replaceHtml($facade, $embed);
                videoFacade.getBrightcoveAds(videoId, adTag);
            });
        },

        replaceHtml: function ($element, $replacementHtml) {
            $element.unbind();
            $element.replaceWith($replacementHtml);
        },

        addPreconnect: function (url) {
            var $link = $(document.createElement('link'))
                .attr('rel', 'preconnect')
                .attr('href', url);
            $('head').append($link);
        },

        preconnect: function () {
            videoFacade.addPreconnect('https://www.youtube-nocookie.com');
            videoFacade.addPreconnect('https://www.google.com');
            $(this).off('mouseover');
        },

        getBrightcoveAds: function (videoId, adTag) {
            window.WaitForIt.wait_for(['nielsen_data_ready', 'sourcepoint_data_ready'], function() {
                var player = bc('bc_player_' + videoId);

                if (window.gusto.ad_switch.enable_ads_preroll === true) {
                    var isPersonalisedAds = gusto.sourcepoint.checkNonIabVendorConsentById(
                            gusto.sourcepoint.nonIabIds.vendors.googleAdManager
                        ),
                        bc_tag_url = adTag;

                    // npa means non-personalised ads
                    if (isPersonalisedAds) {
                        bc_tag_url = bc_tag_url.replace('npa=1', 'npa=0');
                    }

                    if (window.GLOBALADVERTISING) {
                        bc_tag_url +=
                            '&cust_params=' +
                            encodeURIComponent('kvplayer=' + 'brightcove') +
                            encodeURIComponent(
                                '&pageid=' + window.GLOBALADVERTISING.pageid
                            ) +
                            encodeURIComponent('&local=' + window.GLOBALADVERTISING.local) +
                            encodeURIComponent(
                                '&adtest=' + window.GLOBALADVERTISING.adtest
                            ) +
                            encodeURIComponent('&section=' + gusto.CATEGORY_PATH) +
                            encodeURIComponent('&at=' + window.GLOBALADVERTISING.at) +
                            encodeURIComponent(
                                '&sensitive=' + window.GLOBALADVERTISING.sensitive
                            ) +
                            encodeURIComponent('&gdpr=' + window.GLOBALADVERTISING.gdpr) +
                            encodeURIComponent(
                                '&gdpr_pd=' + window.GLOBALADVERTISING.gdpr_pd
                            ) +
                            encodeURIComponent(
                                '&gdpr_consent=' + window.GLOBALADVERTISING.gdpr_consent
                            ) +
                            encodeURIComponent('&nmc=' + gusto.nielsen.segments);
                    }

                    player.ima3({
                        serverUrl: bc_tag_url,
                        requestMode: "onplay",
                    });
                    player.play();
                }

                player.play();
            }, 10000);
        }
    };

    videoFacade.init();
    return videoFacade;
});

