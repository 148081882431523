define('settings',['jquery', 'base_settings', 'devices'], function ($, base_settings, devices) {
    "use strict";

    var settings = $.extend(true, {}, base_settings, {
        font_faces: ['Lora'],
    });

    return settings;
});

