define('gusto/js/app/gusto.dating',['window', 'jquery', 'slick', 'tabs'], function (window, $, slick, tabs) {
    "use strict";

    var dating = {
        profile_selector: '.dating-profiles',

        init: function() {
            this.setup_tabs();
            this.setup_slides();
            this.setup_range_fixer();
        },

        setup_slides: function() {
            $(this.profile_selector).each(function(){
                var $this = $(this),
                    $parent = $this.parent();
                $this.slick({
                    infinite: true,
                    slidesToShow: $this.length,
                    slidesToScroll: 1,
                    prevArrow: $parent.find('.scrollable__prev'),
                    nextArrow: $parent.find('.scrollable__next'),
                    dots: true,
                    dotsClass: 'scrollable__dots',
                    appendDots: $this
                });
            });
        },

        setup_tabs: function() {
            var that = this;
            tabs.setupTabs({
                tabSelector: 'div.dating__pane',
                tabBarClass: 'tabbar tab-bar--desktop-friendly',
                tabCallback: function($itemTabPane){
                    var $profiles = $itemTabPane.find(that.profile_selector);
                    if ($profiles.length) {
                        $profiles.slick("getSlick").refresh();
                    }
                }
            });
        },

        setup_range_fixer: function() {
            // if the user select a max greater than min, or vice versa, switch it around
            var $min = $('#dating_range_min'),
                $max = $('#dating_range_max'),
                check_fix = function() {
                    var max_val = parseInt($max.val(), 10),
                        min_val = parseInt($min.val(), 10);
                    if (min_val > max_val) {
                        $max.val(min_val);
                        $min.val(max_val);
                    }
                };

            $min.change(check_fix);
            $max.change(check_fix);
        },
    };

    dating.init();
});

