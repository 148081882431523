define('gusto/js/app/gusto.searchable_playlist',['window', 'jquery', 'gusto-core', 'slick', 'carousel', 'tab_system', "song-preview", "lazyload", 'jquery-ui-autocomplete', '!domready'], function (window, $, gusto, slick, carousel, tab_system, preview, lazyload) {
    "use strict";

    // NOTE: Depends on carousel as there are some useful carousel methods
    var SHOW_SLIDE_SELECTOR = '.searchable-playlist__shows';
    var $SHOW_SLIDE_OBJECT = $(SHOW_SLIDE_SELECTOR);
    var SECONDS_IN_DAY = 86400;
    var SHOW_ITEM_SELECTOR = SHOW_SLIDE_SELECTOR + ' > div';
    var ON_NOW_SELECTOR = SHOW_ITEM_SELECTOR + '.on_now';
    var TRACK_API_URL = '/api/1.0/on_air/tracks_html/?schedule_id=';
    var SCHEDULE_API_URL = '/api/1.0/on_air/schedules_per_day_html/?date=';

    // Custom extension/override of the jQuery UI autocomplete widget to fix an issue
    // where the width of the suggestions did not match the width of the input field
    $.widget("custom.playlistComplete", $.ui.autocomplete, {
        _resizeMenu: function() {
            var ul = this.menu.element;
            ul.outerWidth(this.element.outerWidth());
        }
    });

    var searchablePlaylist = {
        init: function() {
            var that = this;
            that.setupSearch();
            that.setupSlides();
            that.setupTabs();
        },
        calculateOffset: function(start, end) {
            // Helper function to take unix timestamps for show start/end and get the
            // second offset from midnight that days. Can be negative value in some
            // cases if the show spans across days.
            var offset = start % SECONDS_IN_DAY;
            if ((end % SECONDS_IN_DAY) < offset) {
                offset -= SECONDS_IN_DAY;
            }
            return offset;
        },
        setupSearch: function() {
            $(".searchable-playlist__search__input").playlistComplete({
                source: "/api/1.0/on_air/track_suggestions/",
                minLength: 2,
                select: function(event, ui) {
                    if (ui.item) {
                        var $eventTarget = $(event.target);
                        $eventTarget.val(ui.item.value);
                        $eventTarget.closest('form').submit();
                    }
                }
            });
        },
        setupSlides: function() {
            var that = this;

            $SHOW_SLIDE_OBJECT.each(function(){
                that.setupSlide($(this));
            });
        },
        setupSlide: function($this) {
            var that = this;

            // Grab (and/or cache) data required to manage state
            var currentLiveShowStart;
            var selectedShowStart;
            var selectedShowEnd;
            if (!$this.data('liveshowstart')) {
                var showStart = $(ON_NOW_SELECTOR).data('showstart');
                var showEnd = $(ON_NOW_SELECTOR).data('showend');
                $this.data('liveshowstart', showStart);
                $this.data('selectedshowstart', showStart);
                $this.data('selectedshowend', showEnd);
            }
            currentLiveShowStart = $this.data('liveshowstart');
            selectedShowStart = $this.data('selectedshowstart');
            selectedShowEnd = $this.data('selectedshowend');

            var $showItems = $(SHOW_ITEM_SELECTOR);
            var selectedShowFound = false;
            var selectedShowMidnightOffset = selectedShowStart % SECONDS_IN_DAY;
            if ((selectedShowEnd % SECONDS_IN_DAY) < selectedShowMidnightOffset) {
                selectedShowMidnightOffset -= SECONDS_IN_DAY;
            }

            for (var i = 0; i < $showItems.length; i++) {
                var $showItem = $($showItems[i]);
                var showMidnightOffset = that.calculateOffset($showItem.data('showstart'), $showItem.data('showend'));

                // Apply the selected state to the show that is within the start/end timeframe
                // or the current live show, whichever is found first.
                if (!selectedShowFound && ((showMidnightOffset >= selectedShowMidnightOffset) || ($showItem.data('showstart') == currentLiveShowStart))) {
                    $showItem.addClass('show--selected');
                    selectedShowFound = true;
                }

                // All shows past the current one should be faded and non clickable, apply
                // a class to control this!
                if ($showItem.data('showstart') > currentLiveShowStart) {
                    $showItem.addClass('show--future');
                }

                // Attach the click event for shows that triggers the loading of tracks
                $showItem.on('click', function(){
                    if(!$(this).hasClass('show--selected') && !$(this).hasClass('show--future')) {
                        $this.data('selectedshowstart', $(this).data('showstart'));
                        $this.data('selectedshowend', $(this).data('showend'));
                        that.loadTracks($showItems, $(this));
                    }
                    return false;
                });
            }
            that.setupSlick($this);
        },
        setupSlick: function($this) {
            var that = this;
            var $parent = $this.parent();
            var slidesToShow = carousel.get_slides_shown($parent.closest('.cardset'), SHOW_ITEM_SELECTOR);
            var currentShowIndex = $(ON_NOW_SELECTOR).length ? $(ON_NOW_SELECTOR).index() : 0;

            $this.on('init', function(slick){
                $this.find('.img_wrapper').addClass('visible');
            }).slick({
                infinite: false,
                slidesToShow: slidesToShow.desktop,
                slidesToScroll: slidesToShow.desktop,
                initialSlide: currentShowIndex,
                prevArrow: $parent.find('.scrollable__prev'),
                nextArrow: $parent.find('.scrollable__next'),
                dots: true,
                dotsClass: 'scrollable__dots',
                appendDots: $this,
                responsive: [{
                    breakpoint: gusto.breakpoints.limits.medium[1],
                    settings: {
                        slidesToShow: slidesToShow.mobile,
                        slidesToScroll: slidesToShow.mobile,
                    }
                }]
            });
        },
        setupTabs: function() {
            var that = this;
            tab_system({
                tab_selector: '.searchable_playlist__header__tab-selector',
                tab_title_selector: 'h3.searchable-playlist__header__tab-title',
                tab_bar_class: 'searchable-playlist__header__tab-bar tab-bar',
                active_class: 'active',
                button_label_suffix: 'days',
                display_on_desktop: true,
                display_reversed: true,
                max_tab_number: 7,
                remove_content_after_init: true,
                tab_click_override: function(e, $tab) {
                    var date_selected = $tab.data()['contentData']['tab_name'];
                    var apiURL = SCHEDULE_API_URL + date_selected;

                    $SHOW_SLIDE_OBJECT.addClass('searchable-playlist__shows--loading');
                    $('.searchable-playlist__tracks').addClass('searchable-playlist__tracks--loading');
                    $.get(apiURL, function(scheduleData, status){
                        if (status === 'success') {
                            $SHOW_SLIDE_OBJECT.slick('unslick');
                            $SHOW_SLIDE_OBJECT.html(scheduleData);
                            that.setupSlide($SHOW_SLIDE_OBJECT);
                            $SHOW_SLIDE_OBJECT.removeClass('searchable-playlist__shows--loading');
                            that.loadTracks($(SHOW_ITEM_SELECTOR), $('.show--selected', $SHOW_SLIDE_OBJECT));
                        }
                    });
                },
                responsive:{
                    level1: {
                        max_tab_number: 3,
                    },
                    level2: {
                        max_tab_number: 5,
                    },
                    level3: {
                        max_tab_number: 7,
                    }
                }
            });
        },
        loadTracks: function($showItems, $this) {
            var that = this;
            var scheduleId = $this.data('scheduleid');
            var apiURL = TRACK_API_URL + scheduleId;

            $showItems.removeClass('show--selected');
            $this.addClass('show--selected');

            var $playlistContext = $this.closest('.searchable-playlist__header').next('.searchable-playlist__tracks');

            $playlistContext.addClass('searchable-playlist__tracks--loading').load(apiURL, function(response, status){
                // Only handle success, on a failure we will leave tracks faded out!
                if(status === 'success') {
                    $('.song .img_wrapper img', $playlistContext).each(function(){
                        var imageSrc = $(this).data('src') || $(this).data('lazy');
                        if(imageSrc) {
                            $(this).attr('src', imageSrc).removeAttr('data-src').removeData('src');
                            $(this).closest('.img_wrapper').addClass('visible');
                        }
                    });
                    $(this).removeClass('searchable-playlist__tracks--loading');
                    preview.init_setup($('.song .song-preview', $playlistContext));
                    lazyload.loadImages($('.searchable-playlist__tracks .js-lazy'));
                }
            });
        }
    };
    searchablePlaylist.init();

    return searchablePlaylist;
});

