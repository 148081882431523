define('tab_system',['window', 'jquery', 'underscore'], function (window, $, _) {
    "use strict";

    var tab_system = {
        /**
         * Private Vars to use
         */
        injection_queue: [],


        /**
         * The templates used in the tab system
         */
        templates: function (what_template, options) {
            switch (what_template) {
                case "responsive_tab_bar" :
                    return $("<div class='tab-bar--responsive'>" +
                        "<div class='tab-bar__scroll-btns'>" +
                        "<div class='tab-bar__scroll-btns__left-btn'>" +
                        "<a href='#'>" +
                        "<span class='tab-bar__scroll-btns__left-btn-text'>" + options.left_btn_text + "</span>" +
                        "</a>" +
                        "</div>" +
                        "<div class='tab-bar__scroll-btns__right-btn'>" +
                        "<a href='#'>" +
                        "<span class='tab-bar__scroll-btns__right-btn-text'>" + options.right_btn_text + "</span>" +
                        "</a>" +
                        "</div>" +
                        "</div>" +
                        "</div>");

                case "tab_bar" :
                    return $("<ul class='" + options.class_name + "'></ul>");

                case "tab_item_factory" :
                    return $("<a href='#'></a>").append(options.tab_title);

                case "tab_item" :
                    return $("<li></li>").append($("<div class='tab-bar__label'></div>").append(
                        tab_system.templates('tab_item_factory', {tab_title: options.tab_title})
                    ));
            }
        },


        /**
         * Searches for and initialises a tab system
         * based on a series of options
         */
        set_up_tab_system: function (input_options) {

            var options = $.extend({
                tab_selector: 'div.tab',            // Class of element to tabbify
                tab_title_selector: 'h3',           // Where the title to show on the tab is located in the content
                tab_content_selector: 'div.row',    // The overall class to search for tabbable elements in
                tab_bar_class: 'tab-bar',           // Class to add to the end result tab bar
                button_label_suffix: "items",
                display_on_desktop: true,
                tab_click_override: null,           // Function to call INSTEAD of triggering tab reveal
                max_tab_number: 3,                  // Default tab number
                active_class: 'active',             // Class to add to the "active" tab
                responsive: null,                   // Object dictating settings overrides
                callback_func: null,                // Function to call AS WELL AS triggering tab reveal
                display_reversed: null,             // Whether to reverse the display order of tabs
                remove_content_after_init: null     // Remove the original elements that were turned into tabs
            }, input_options);


            if (!$(options.tab_selector).length){
                return
            }

            if (options.responsive) {
                var current_state = tab_system.get_current_card_state($($(options.tab_selector)[0]));

                // stash the original state for reference (if not overwritten in responsive)
                if (!options.responsive[current_state]) {
                    options.responsive[current_state] = {
                        tab_title_selector: options.tab_title_selector,
                        tab_bar_class: options.tab_bar_class,
                        tab_click_override: options.tab_click_override,
                        max_tab_number: options.max_tab_number,
                        active_class: options.active_class
                    };
                }

                tab_system.update_tab_system_state($(options.tab_selector), options, current_state);
            }

            if (options.display_on_desktop){
                 options.tab_bar_class += " tab-bar--desktop-friendly";
            }

            // Make sure the correct data is displaying
            tab_system.set_up_tab_content(options);

            // Inject tab bars into the DOM
            return tab_system.inject_tab_bars(options);
        },


        /**
         * Find all the content modules that are to be
         * turned into tabs and trigger build for each
         */
        set_up_tab_content: function (opt) {
            $(opt.tab_content_selector).each(function () {
                var $tab_group = $(),
                    $tab_content = $(this).find(opt.tab_selector).siblings().andSelf();

                $.each($tab_content, function () {
                    var $this = $(this);

                    // if content matches
                    if ($this.is(opt.tab_selector)) {

                        // if not hit max OR is responsive
                        if (opt.responsive || $tab_group.length < opt.max_tab_number) {
                            $this.addClass('tab_content');
                            $tab_group = $tab_group.add($this);
                        }
                        else {
                            // build and reset
                            tab_system.build_tabs($tab_group, opt);
                            $tab_group = $().add($this);
                        }
                    }
                    else {
                        // build and reset
                        tab_system.build_tabs($tab_group, opt);
                        $tab_group = $();
                    }

                });

                tab_system.build_tabs($tab_group, opt);
            });
        },


        /**
         * Creates the dom elements for each tab
         * ready for injection
         */
        build_tabs: function ($tab_group, opt) {
            var $bar_to_inject;
            var active_tab_index = 0;

            // catch any leftover tab-panes that are empty or have one entry and ensure they're visible
            if ($tab_group.length <= 1) {
                $($tab_group[0]).addClass(opt.active_class);
                return undefined;
            }
            // retrieve the template for a tab bar
            var $tab_bar = tab_system.templates("tab_bar", {
                class_name: opt.tab_bar_class
            }),
                tab_bar_items = [];

            // assign variables to tab bars that are needed for tab func
            $tab_bar.data({
                active_class: opt.active_class,
                tab_selector: opt.tab_selector
            });

            // set the tab bar inject position to above the first item
            opt.inject_before = $($tab_group[0]);

            // for each tab item in a tab set populate a template
            $.each($tab_group, function (index) {
                var $this = $(this);

                var $tab_title_elem = $this.find(opt.tab_title_selector);
                var tab_title = $tab_title_elem.data('tabname') || $tab_title_elem.text();

                if ($tab_title_elem.hasClass('active')){
                    active_tab_index = index;
                }

                var $tab_item = tab_system.templates("tab_item", {
                    tab_title: tab_title
                });
                $tab_item.data('associatedContent', $this);

                // if we're removing the content after init then assign
                // all the data attributes on the content to the tab for
                // use in overwriting functions
                if (opt.remove_content_after_init) {
                    $tab_item.data('contentData', $this.data());
                }

                tab_system.add_tab_listeners($tab_item, opt);

                if (opt.display_reversed) {
                    tab_bar_items.unshift($tab_item);
                    if (index === $tab_group.length - 1) {
                        $this.addClass(opt.active_class);
                    }
                }
                else {
                    tab_bar_items.push($tab_item);
                    if (index === 0) {
                        $this.addClass(opt.active_class);
                    }
                }
            });

            // add all tab items into the tab bar parent
            $tab_bar.append(tab_bar_items);

            if (opt.display_reversed) {
                active_tab_index = tab_bar_items.length - 1 - active_tab_index
            }
            tab_bar_items[active_tab_index].addClass("active");


            if (opt.responsive) {

                tab_system.update_tab_system_state(opt.inject_before, opt);
                opt.visible_tabs = tab_system.get_visible_tabs(active_tab_index, opt.max_tab_number, tab_bar_items.length);

                tab_system.tab_scroll_btn_dom_handler(tab_bar_items, opt.visible_tabs, ["", ""]);

                $bar_to_inject = tab_system.make_tab_system_responsive($tab_bar, tab_bar_items, $tab_group, opt);
                $tab_bar.addClass('tabs-' + opt.max_tab_number);

                opt.tab_bar_items = tab_bar_items;
            }
            else {
                $tab_bar.addClass('tabs-' + $tab_group.length);
                $bar_to_inject = $tab_bar;
            }

            // add the tab bar parent to the injection queue
            tab_system.injection_queue.push({
                'tab_bar': $bar_to_inject[0],
                'inject_before': opt.inject_before
            });
        },


        /**
         * Adds the listeners to the tabs and handles the
         * active and inactive states of tabs and panes
         */
        add_tab_listeners: function ($tab, opt) {

            $tab.on('mousedown touchstart', function (e) {
                e.stopPropagation();

                // gather data
                var $this = $(this);
                var $thisBar = $this.closest(".tab-bar");
                var active_tab_class = $thisBar.data('active_class');
                var tabIndex = $this.index();
                var $contentBlocks = $($thisBar.data('tab_selector'));

                // deal with tabs
                $thisBar.siblings(".tab-bar").add($thisBar).each(function(tabBarIndex, tabBar) {
                    var $tabBar = $(tabBar);
                    $tabBar.find("li").removeClass(active_tab_class);
                    $($tabBar.find("li").get(tabIndex)).addClass(active_tab_class);
                });

                // deal with content
                $contentBlocks.removeClass(opt.active_class);
                var onClickFunction = opt.tab_click_override;
                if (onClickFunction) {
                    onClickFunction(e, $this, $thisBar);
                }
                else {
                    $this.data('associatedContent').addClass(active_tab_class);
                }

                // Trigger lazy loading of any images revealed by switching tabs using the global manager
                window._slm.triggerListeners();

                if (opt.callback_func) {
                    opt.callback_func();
                }

                return false;
            });

            // Block the standard click event completely to stop server hits
            $tab.on('click', function (e) {
                e.preventDefault();
                return false;
            });
        },


        /**
         * Checks the current state of the card and overwrites
         * any variables specified in the initial options
         */
        update_tab_system_state: function ($elem, opt, known_state) {
            opt.breakpoint = known_state || tab_system.get_current_card_state($elem);

            if (opt.responsive[opt.breakpoint]) {
                for (var attr in opt.responsive[opt.breakpoint]) {
                    opt[attr] = opt.responsive[opt.breakpoint][attr];
                }
            }
        },


        /**
         * Take the pre-built tabs and put them into a
         * pseudo responsive template with see more tags
         */
        make_tab_system_responsive: function ($static_tab_bar, tab_bar_items, tab_group, opt) {

            var tab_scroll_btn_values = tab_system.tab_scroll_btn_value_handler({
                tab_bar_items: tab_bar_items,
                max_tab_number: opt.max_tab_number,
                visible_tabs: opt.visible_tabs,
                button_label_suffix: opt.button_label_suffix,
                update_dom: false
            });

            var $responsive_tab_bar = tab_system.templates('responsive_tab_bar', {
                left_btn_text: tab_scroll_btn_values.left,
                right_btn_text: tab_scroll_btn_values.right
            }).append($static_tab_bar);

            tab_system.add_responsive_tab_listeners($responsive_tab_bar, tab_bar_items, opt);

            return $responsive_tab_bar;
        },


        /**
         *  Give it a card, it will return a level, if it
         *  currently being viewed on small breakpoint it
         *  will return level1
         */
        get_current_card_state: function ($current_card) {
            if (window.gusto.breakpoints.check('medium-down')) {
                return "level1";
            }
            else {
                var $cardset = $current_card.closest('.cardset');

                if ($cardset.hasClass('level1')) {
                    return "level1";
                }
                else if ($cardset.hasClass('level2')) {
                    return "level2";
                }
                else {
                    return "level3";
                }
            }
        },


        /**
         * As we're using a responsive template the 'see more'
         * buttons need listeners and logic, handled here
         */
        add_responsive_tab_listeners: function ($responsive_tab_bar, tab_bar_items, opt) {
            var $left_btn = $responsive_tab_bar.find('.tab-bar__scroll-btns__left-btn'),
                $right_btn = $responsive_tab_bar.find('.tab-bar__scroll-btns__right-btn');

            var btn_handler = function (e, src) {
                e.stopPropagation();

                if (src === 'left') {
                    opt.visible_tabs.start -= opt.max_tab_number;
                    opt.visible_tabs.end -= opt.max_tab_number;
                }
                else if (src === 'right') {
                    opt.visible_tabs.start += opt.max_tab_number;
                    opt.visible_tabs.end += opt.max_tab_number;
                }

                if (opt.visible_tabs.start < 0) {
                    opt.visible_tabs.start = 0;
                    opt.visible_tabs.end = opt.max_tab_number;
                }
                if (opt.visible_tabs.end > tab_bar_items.length) {
                    opt.visible_tabs.end = tab_bar_items.length;
                    opt.visible_tabs.start = tab_bar_items.length - opt.max_tab_number;
                }

                tab_system.tab_scroll_btn_value_handler({
                    tab_bar_items: tab_bar_items,
                    max_tab_number: opt.max_tab_number,
                    button_label_suffix: opt.button_label_suffix,
                    visible_tabs: opt.visible_tabs,
                    update_dom: true
                });

                return false;
            };

            var defaults_blocker = function (e) {
                e.preventDefault();
                return false;
            };

            $(window).on('orientationchange resize', _.throttle(function () {
                var new_bp = tab_system.get_current_card_state($(opt.tab_bar_items[0]));

                if (opt.breakpoint !== new_bp) {
                    opt.breakpoint = new_bp;

                    tab_system.update_tab_system_state($(opt.tab_selector), opt, new_bp);

                    var current_selected_index = $(opt.tab_bar_items[0]).parent().find(".active").index();

                    opt.visible_tabs = tab_system.get_visible_tabs(current_selected_index, opt.max_tab_number, opt.tab_bar_items.length);

                    tab_system.tab_scroll_btn_value_handler({
                        tab_bar_items: opt.tab_bar_items,
                        max_tab_number: opt.max_tab_number,
                        visible_tabs: opt.visible_tabs,
                        button_label_suffix: opt.button_label_suffix,
                        update_dom: true
                    });
                }
            }, 500));

            var debounced_check = _.debounce(btn_handler, 300);

            $left_btn.on('mousedown touchstart', function (e) {
                debounced_check(e, 'left');
            });
            $right_btn.on('mousedown touchstart', function (e) {
                debounced_check(e, 'right');
            });

            // Block the standard click event completely to stop server hits
            $left_btn.on('click', defaults_blocker);
            $right_btn.on('click', defaults_blocker);
        },


        /**
         * Injects the html elements needed for a
         * given tab system
         */
        inject_tab_bars: function (opt) {
            // loop through injection queue and insert elements before their specified sibling
            for (var i=0; i<tab_system.injection_queue.length; i++) {
                var tab_to_inject = tab_system.injection_queue[i];
                tab_to_inject.inject_before.before(tab_to_inject.tab_bar);
            }
            if (opt.remove_content_after_init) {
                $(opt.tab_selector).remove();
            }
            return tab_system.injection_queue;
        },


        /**
         * Called on resize or reconfiguration of
         * tabs or loading of 'responsive' tabs
         */
        tab_scroll_btn_value_handler: function (opt) {

            var leftover_left = opt.visible_tabs.start,
                leftover_right = opt.tab_bar_items.length - opt.visible_tabs.end,

                next_on_left = Math.min(opt.max_tab_number, leftover_left),
                next_on_right = Math.min(opt.max_tab_number, leftover_right),

                button_labels = {
                    left: next_on_left > 0 ? "« Previous " + next_on_left + " " + opt.button_label_suffix : "",
                    right: next_on_right > 0 ? "Next " + next_on_right + " " + opt.button_label_suffix + " »" : ""
                };

            if (opt.update_dom) {
                tab_system.tab_scroll_btn_dom_handler(
                    opt.tab_bar_items,
                    opt.visible_tabs,
                    [
                        button_labels.left,
                        button_labels.right
                    ]
                );
            }
            return button_labels;
        },


        /**
         * Update both the dates on the tabs themselves
         * and the next/previous buttons
         */
        tab_scroll_btn_dom_handler: function (tab_bar_items, visible_tabs, scroll_btn_vars) {
            var cur_visible_tab_indexes = tab_bar_items.slice(visible_tabs.start, visible_tabs.end);
            var $ul_elem = $(tab_bar_items[0]).parent();
            var $cardset_elem = $ul_elem.closest(".cardset").first();
            var $visible_tabs = $ul_elem.find('li.tab-bar__visible-tab');

            $ul_elem.removeClass("tabs-2 tabs-3 tabs-4 tabs-5 tabs-6 tabs-7");
            $ul_elem.addClass("tabs-" + cur_visible_tab_indexes.length);
            $visible_tabs.removeClass('tab-bar__visible-tab tab-bar__visible-tab--first tab-bar__visible-tab--last');

            for (var j = 0; j < cur_visible_tab_indexes.length; j++) {
                var $elem = $(cur_visible_tab_indexes[j]);

                $elem.addClass('tab-bar__visible-tab');

                // assign first and last classes for further styling
                if ( j === 0 ) {
                    $elem.addClass('tab-bar__visible-tab--first');
                }
                if ( j === cur_visible_tab_indexes.length - 1 ) {
                    $elem.addClass('tab-bar__visible-tab--last');
                }
            }

            if ($cardset_elem) {
                $($cardset_elem.find(".tab-bar__scroll-btns__left-btn-text")).text(scroll_btn_vars[0]);
                $($cardset_elem.find(".tab-bar__scroll-btns__right-btn-text")).text(scroll_btn_vars[1]);
            }
        },


        /**
         * Based on current index of active tab
         * and current breakpoint return visible tabs
         */
        get_visible_tabs: function (cur_active_index, max_viewable, tab_total) {

            var tab_index_max = tab_total - 1; // lengths and indexes start at 1 and 0 respectively
            var avg_split = Math.floor((max_viewable - 1) / 2);
            var right_bound = cur_active_index + avg_split;
            var left_bound = cur_active_index - avg_split;

            // add leftover to right side (could be either, only for uneven numbers)
            right_bound += max_viewable - (right_bound - left_bound);

            if (left_bound < 0) {
                right_bound = max_viewable;
                left_bound = 0;
            }
            if (right_bound > tab_index_max) {
                left_bound = tab_total - max_viewable;
                right_bound = tab_total;
            }

            return {
                start: left_bound,
                end: right_bound
            };
        }
    };

    return tab_system.set_up_tab_system;
});

