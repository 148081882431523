define('song-preview',['window', 'jquery'], function (window, $) {
    'use strict';

    var PreviewPlayer = {

        active_clip: undefined,

        init: function() {
            this.init_setup($('.song-preview'));
            return this;
        },

        init_setup: function($elements) {
            var that = this;
            $elements.each(function(){
                var $this = $(this);
                that.player_setup(
                    $this.find('.song-preview__action'),
                    $this.find('.song-preview__progress'),
                    $this.find('.song-preview__dot'),
                    $this.find('.song-preview__seeker'),
                    $this.find('.song-preview__audio').get(0)
                );
            });
        },

        get_stop_obj: function(audio) {
            return {
                stop: function() {
                    audio.pause();
                    audio.currentTime = 0;
                    audio.dispatchEvent(new Event('ended'));
                }
            };
        },

        stop_audio_playback: function() {
            if (typeof this.active_clip != 'undefined') {
                this.active_clip.stop();
            }
        },

        player_setup: function($actionBtn, $progressBar, $dot, $seeker, audio) {
            if (typeof Audio == 'undefined') {
                $actionBtn.remove();
                $progressBar.remove();
                $dot.remove();
                $seeker.remove();
                return;
            }

            var that = this;
            $actionBtn.on('click', function() {
                if (audio.paused) {
                    that.stop_audio_playback();
                    that.active_clip = that.get_stop_obj(audio);
                    $actionBtn.addClass('song-preview__action--playing');
                    audio.play();
                }
                else {
                    that.active_clip = undefined;
                    $actionBtn.removeClass('song-preview__action--playing');
                    audio.pause();
                }
            });
            audio.addEventListener('ended', function() {
                $actionBtn.removeClass('song-preview__action--playing');
                that.set_progress($progressBar, $dot, 0);
            });
            audio.addEventListener('timeupdate', function() {
                that.set_progress($progressBar, $dot, this.currentTime / this.duration);
            });
            $seeker.on('click', function(e) {
                var percent = (e.offsetX / $progressBar.outerWidth()).toFixed(2);
                audio.currentTime = percent * audio.duration;
                that.set_progress($progressBar, $dot, percent);
            });

            $dot.data('initial-offset', parseInt($dot.css('left'), 10));
        },

        set_progress: function($bar, $dot, val) {
            $bar.val(val);
            var bar_width = $bar.outerWidth();
            var initial = $dot.data('initial-offset');
            $dot.css({left: initial + val * bar_width});
        }
    };

    return PreviewPlayer.init();
});

